@media screen and (max-width: 800px) {
  @function trendsWidth($num) {
    @return calc($num / 14.4) + vw
  };
  
  @function trendsHeight($num) {
    @return calc($num / 14.4) + vw
  };
  .home-page {
    overflow-y: auto;
    overflow-x: hidden;
    .title {
      width: trendsWidth(1440);
      height: trendsHeight(72);
      background: rgba(255,255,255,0);
      box-shadow: inset 0px -1px 0px 0px rgba(255,255,255,0.2);
      display: flex;
      justify-content: space-between;
      position: fixed;
      top: 0;
      z-index: 1000;
      .left {
        margin-left: trendsWidth(120);
        display: flex;
        align-items: center;
        .img {
          width: trendsWidth(40);
          height: trendsHeight(41);
          margin-right: trendsWidth(12);
        }
        .img-text {
          width: trendsWidth(98);
          height: trendsHeight(19);
        }
      }
      .tabs-box {
        margin-right: trendsWidth(119);
        display: flex;
        .tab-item {
          margin-left: trendsHeight(40);
          font-size: trendsWidth(16);
          font-weight: 600;
          color: #FFFFFF;
          line-height: trendsHeight(22);
          padding-top: trendsHeight(25);
          cursor: pointer;
        }
        .active {
          border-bottom: trendsHeight(3) solid #4BB773;
        }
      }
    }
    .green-title {
      background: #FFFFFF;
      box-shadow: inset 0px -1px 0px 0px rgba(255,255,255,0.2);
      .tabs-box {
        .tab-item {
          color: #181818;
        }
        .active {
          color: #4BB773;
        }
      }
    }
    .box {
      height: trendsHeight(900);
      position: relative;
      .img {
        position: absolute;
        width: 100%;
        height: 100%;
      }
      .xiala-img {
        position: absolute;
        bottom: trendsHeight(46);
        width: trendsWidth(20.17);
        height: trendsHeight(11.78);
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
      }
    }
    .box1, .box2 {
      .quan-box {
        position: absolute;
        width: trendsWidth(474);
        height: trendsWidth(474);
        background-color: #000000;
        opacity: 0.2;
        border-radius: 50%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .box1 {
      .text-box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: trendsWidth(324);
        height: trendsHeight(144);
        color: #fff;
        text-align: center;
        z-index: 10;
        .text1 {
          font-size: trendsWidth(52);
        }
        .text2 {
          font-size: trendsWidth(36);
        }
      }
      .green-1 {
        width: trendsWidth(101);
        height: trendsHeight(101);
        background-color: #4BB773;
        opacity: 0.5;
        border-radius: 50%;
        top: trendsHeight(235);
        left: trendsWidth(492);
        position: absolute;
      }
      .green-2 {
        width: trendsWidth(62);
        height: trendsHeight(62);
        background: #4BB773;
        opacity: 0.5;
        border-radius: 50%;
        bottom: trendsHeight(371);
        right: trendsWidth(539);
        position: absolute;
      }
    }
    .box2 {
      .text-box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        text-align: center;
        z-index: 10;
        width: trendsWidth(660);
        .text1 {
          font-size: trendsWidth(52);
        }
        .text2 {
          margin-top: trendsHeight(28);
          font-size: trendsWidth(18);
          font-weight: 400;
          color: #FFFFFF;
        }
      }
      .green-1 {
        width: trendsWidth(101);
        height: trendsHeight(101);
        background-color: #4BB773;
        opacity: 0.5;
        border-radius: 50%;
        top: trendsHeight(235);
        left: trendsWidth(492);
        position: absolute;
      }
      .green-2 {
        width: trendsWidth(62);
        height: trendsHeight(62);
        background: #4BB773;
        opacity: 0.5;
        border-radius: 50%;
        bottom: trendsHeight(262);
        left: trendsWidth(850);
        position: absolute;
      }
    }
    .box3, .box4 {
      .img1 {
        width: trendsWidth(60);
        height: trendsWidth(60);
        position: absolute;
        top: trendsHeight(240);
        left: trendsWidth(120);
      }
      .img2 {
        width: trendsWidth(411);
        height: trendsHeight(47);
        position: absolute;
        top: trendsHeight(340);
        left: trendsWidth(120);
      }
      .img3, .img4 {
        position: absolute;
        width: trendsWidth(978);
        height: trendsHeight(176);
        top: trendsHeight(240);
      }
      .img4 {
        top: trendsHeight(482);
        left: trendsWidth(450);
      }
      .img5 {
        position: absolute;
        width: trendsWidth(478);
        height: trendsHeight(731);
        top: trendsHeight(116);
        right: trendsWidth(159);
      }
      .text {
        width: trendsWidth(600);
        height: trendsHeight(84);
        font-size: trendsWidth(16);
        font-weight: 400;
        color: #666666;
        position: absolute;
        left: trendsWidth(120);
        top: trendsHeight(449);
      }
    }
    .box4 {
      .img1 {
        left: trendsWidth(1248);
        top: trendsHeight(242);
      }
      .img2 {
        width: trendsWidth(446);
        height: trendsHeight(60);
        top: trendsHeight(358);
        left: trendsWidth(720);
      }
      .img5 {
        top: trendsHeight(116);
        left: trendsWidth(144);
      }
      .text {
        width: trendsWidth(600);
        height: trendsHeight(112);
        font-size: trendsWidth(16);
        font-weight: 400;
        color: #666666;
        top: trendsHeight(449);
        left: trendsWidth(720);
      }
    }
    .box5 {
      .scroll-box {
        height: 100%;
        overflow-x: auto;
      }
      ::-webkit-scrollbar{
        display:none;
      }
      
      .center {
        display: flex;
        flex-wrap: nowrap;
        .box {
          width: trendsWidth(1440);
          .green-yuan, .img-1 {
            position: absolute;
            width: trendsWidth(520);
            height: trendsHeight(520);
            background: #4BB773;
            border-radius: 50%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
          .img-1 {
            width: trendsWidth(709);
            height: trendsHeight(632);
            background: none;
            border-radius: 0;
          }
          .img-2 {
            width: trendsWidth(605);
            height: trendsHeight(63);
            position: absolute;
            bottom: trendsHeight(96);
            left: trendsWidth(123);
          }
          .img-3 {
            position: absolute;
            width: trendsWidth(32);
            height: trendsHeight(32);
            bottom: trendsHeight(147);
            right: trendsWidth(498);
          }
          .img-4 {
            position: absolute;
            width: trendsWidth(66);
            height: trendsHeight(32);
            bottom: trendsHeight(144);
            right: trendsWidth(409);
          }
          .text {
            position: absolute;
            width: trendsWidth(358);
            font-size: trendsWidth(16);
            font-weight: 400;
            color: #666666;
            bottom: trendsHeight(98);
            right: trendsWidth(120);
          }
        }
      }
      .img-left, .img-right {
        position: absolute;
        width: trendsWidth(60);
        height: trendsHeight(60);
        top: 50%;
        transform: translateY(-50%);
        left: trendsWidth(186);
        cursor: pointer;
      }
      .img-right {
        left: auto;
        right: trendsWidth(186);
      }
    }
    .box6 {
      position: relative;
      .img-1 {
        position: absolute;
        height: trendsHeight(692);
        width: 100%;
      }
      .img-2 {
        position: absolute;
        width: trendsWidth(356);
        height: trendsHeight(32);
        top: trendsHeight(273);
        left: trendsWidth(120);
      }
      .green-line {
        position: absolute;
        width: trendsWidth(40);
        height: trendsHeight(6);
        background: #4BB773;
        top: trendsHeight(299);
        left: trendsWidth(498);
      }
      .tootip-text {
        width: trendsWidth(600);
        height: trendsHeight(72);
        font-size: trendsWidth(14);
        font-weight: 400;
        color: #666666;
        position: absolute;
        top: trendsHeight(341);
        left: trendsWidth(120);
      }
      .footer {
        position: absolute;
        height: trendsHeight(208);
        background: #181818;
        width: 100%;
        bottom: 0;
        display: flex;
        flex-direction: column;
        color: #FFFFFF;
        .top {
          height: trendsHeight(159);
          box-shadow: inset 0px -1px 0px 0px #474747;
          display: flex;
          justify-content: space-between;
          .left {
            margin-left: trendsWidth(120);
            .text1 {
              font-size: trendsWidth(18);
              font-weight: 500;
              margin-bottom: trendsHeight(24);
              margin-top: trendsHeight(36);
            }
            .text2 {
              font-size: trendsWidth(14);
              font-weight: 400;
            }
          }
          .right {
            display: flex;
            align-items: center;
            margin-right: trendsWidth(120);
            .img-box {
              width: trendsWidth(80);
              height: trendsHeight(80);
              margin-right: trendsWidth(16);
              .img {
                position: static;
                width: 100%;
                height: 100%;
              }
            }
            .text-box {
              font-size: trendsWidth(12);
            }
          }
        }
        .bottom {
          flex: 1;
          display: flex;
          box-sizing: border-box;
          align-items: center;
          justify-content: space-between;
          font-size: trendsWidth(12);
          color: #999;
          justify-content: space-evenly;
          flex-wrap: wrap;
        }
      }
    }
  }
}
@media screen and (min-width: 800px) {
  @function trendsWidth($num) {
    @return calc($num / 14.4) + vw
  };
  
  @function trendsHeight($num) {
    @return calc($num / 9) + vh
  };
  .home-page {
    overflow-y: auto;
    overflow-x: hidden;
    .title {
      width: trendsWidth(1440);
      height: trendsHeight(72);
      background: rgba(255,255,255,0);
      box-shadow: inset 0px -1px 0px 0px rgba(255,255,255,0.2);
      display: flex;
      justify-content: space-between;
      position: fixed;
      top: 0;
      z-index: 1000;
      .left {
        margin-left: trendsWidth(120);
        display: flex;
        align-items: center;
        .img {
          width: trendsWidth(40);
          height: trendsHeight(41);
          margin-right: trendsWidth(12);
        }
        .img-text {
          width: trendsWidth(98);
          height: trendsHeight(19);
        }
      }
      .tabs-box {
        margin-right: trendsWidth(119);
        display: flex;
        .tab-item {
          margin-left: trendsHeight(40);
          font-size: trendsWidth(16);
          font-weight: 600;
          color: #FFFFFF;
          line-height: trendsHeight(22);
          padding-top: trendsHeight(25);
          cursor: pointer;
        }
        .active {
          border-bottom: trendsHeight(3) solid #4BB773;
        }
      }
    }
    .green-title {
      background: #FFFFFF;
      box-shadow: inset 0px -1px 0px 0px rgba(255,255,255,0.2);
      .tabs-box {
        .tab-item {
          color: #181818;
        }
        .active {
          color: #4BB773;
        }
      }
    }
    .box {
      height: trendsHeight(900);
      position: relative;
      .img {
        position: absolute;
        width: 100%;
        height: 100%;
      }
      .xiala-img {
        position: absolute;
        bottom: trendsHeight(46);
        width: trendsWidth(20.17);
        height: trendsHeight(11.78);
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
      }
    }
    .box1, .box2 {
      .quan-box {
        position: absolute;
        width: trendsWidth(474);
        height: trendsWidth(474);
        background-color: #000000;
        opacity: 0.2;
        border-radius: 50%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .box1 {
      .text-box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: trendsWidth(324);
        height: trendsHeight(144);
        color: #fff;
        text-align: center;
        z-index: 10;
        .text1 {
          font-size: trendsWidth(52);
        }
        .text2 {
          font-size: trendsWidth(36);
        }
      }
      .green-1 {
        width: trendsWidth(101);
        height: trendsHeight(101);
        background-color: #4BB773;
        opacity: 0.5;
        border-radius: 50%;
        top: trendsHeight(235);
        left: trendsWidth(492);
        position: absolute;
      }
      .green-2 {
        width: trendsWidth(62);
        height: trendsHeight(62);
        background: #4BB773;
        opacity: 0.5;
        border-radius: 50%;
        bottom: trendsHeight(371);
        right: trendsWidth(539);
        position: absolute;
      }
    }
    .box2 {
      .text-box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        text-align: center;
        z-index: 10;
        width: trendsWidth(660);
        .text1 {
          font-size: trendsWidth(52);
        }
        .text2 {
          margin-top: trendsHeight(28);
          font-size: trendsWidth(18);
          font-weight: 400;
          color: #FFFFFF;
        }
      }
      .green-1 {
        width: trendsWidth(101);
        height: trendsHeight(101);
        background-color: #4BB773;
        opacity: 0.5;
        border-radius: 50%;
        top: trendsHeight(235);
        left: trendsWidth(492);
        position: absolute;
      }
      .green-2 {
        width: trendsWidth(62);
        height: trendsHeight(62);
        background: #4BB773;
        opacity: 0.5;
        border-radius: 50%;
        bottom: trendsHeight(262);
        left: trendsWidth(850);
        position: absolute;
      }
    }
    .box3, .box4 {
      .img1 {
        width: trendsWidth(60);
        height: trendsWidth(60);
        position: absolute;
        top: trendsHeight(240);
        left: trendsWidth(120);
      }
      .img2 {
        width: trendsWidth(411);
        height: trendsHeight(47);
        position: absolute;
        top: trendsHeight(340);
        left: trendsWidth(120);
      }
      .img3, .img4 {
        position: absolute;
        width: trendsWidth(978);
        height: trendsHeight(176);
        top: trendsHeight(240);
      }
      .img4 {
        top: trendsHeight(482);
        left: trendsWidth(450);
      }
      .img5 {
        position: absolute;
        width: trendsWidth(478);
        height: trendsHeight(731);
        top: trendsHeight(116);
        right: trendsWidth(159);
      }
      .text {
        width: trendsWidth(600);
        height: trendsHeight(84);
        font-size: trendsWidth(16);
        font-weight: 400;
        color: #666666;
        position: absolute;
        left: trendsWidth(120);
        top: trendsHeight(449);
      }
    }
    .box4 {
      .img1 {
        left: trendsWidth(1248);
        top: trendsHeight(242);
      }
      .img2 {
        width: trendsWidth(446);
        height: trendsHeight(60);
        top: trendsHeight(358);
        left: trendsWidth(720);
      }
      .img5 {
        top: trendsHeight(116);
        left: trendsWidth(144);
      }
      .text {
        width: trendsWidth(600);
        height: trendsHeight(112);
        font-size: trendsWidth(16);
        font-weight: 400;
        color: #666666;
        top: trendsHeight(449);
        left: trendsWidth(720);
      }
    }
    .box5 {
      .scroll-box {
        height: 100%;
        overflow-x: auto;
      }
      ::-webkit-scrollbar{
        display:none;
      }
      
      .center {
        display: flex;
        flex-wrap: nowrap;
        .box {
          width: trendsWidth(1440);
          .green-yuan, .img-1 {
            position: absolute;
            width: trendsWidth(520);
            height: trendsHeight(520);
            background: #4BB773;
            border-radius: 50%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
          .img-1 {
            width: trendsWidth(709);
            height: trendsHeight(632);
            background: none;
            border-radius: 0;
          }
          .img-2 {
            width: trendsWidth(605);
            height: trendsHeight(63);
            position: absolute;
            bottom: trendsHeight(96);
            left: trendsWidth(123);
          }
          .img-3 {
            position: absolute;
            width: trendsWidth(32);
            height: trendsHeight(32);
            bottom: trendsHeight(147);
            right: trendsWidth(498);
          }
          .img-4 {
            position: absolute;
            width: trendsWidth(66);
            height: trendsHeight(32);
            bottom: trendsHeight(144);
            right: trendsWidth(409);
          }
          .text {
            position: absolute;
            width: trendsWidth(358);
            font-size: trendsWidth(16);
            font-weight: 400;
            color: #666666;
            bottom: trendsHeight(98);
            right: trendsWidth(120);
          }
        }
      }
      .img-left, .img-right {
        position: absolute;
        width: trendsWidth(60);
        height: trendsHeight(60);
        top: 50%;
        transform: translateY(-50%);
        left: trendsWidth(186);
        cursor: pointer;
      }
      .img-right {
        left: auto;
        right: trendsWidth(186);
      }
    }
    .box6 {
      position: relative;
      .img-1 {
        position: absolute;
        height: trendsHeight(692);
        width: 100%;
      }
      .img-2 {
        position: absolute;
        width: trendsWidth(356);
        height: trendsHeight(32);
        top: trendsHeight(273);
        left: trendsWidth(120);
      }
      .green-line {
        position: absolute;
        width: trendsWidth(40);
        height: trendsHeight(6);
        background: #4BB773;
        top: trendsHeight(299);
        left: trendsWidth(498);
      }
      .tootip-text {
        width: trendsWidth(600);
        height: trendsHeight(72);
        font-size: trendsWidth(14);
        font-weight: 400;
        color: #666666;
        position: absolute;
        top: trendsHeight(341);
        left: trendsWidth(120);
      }
      .footer {
        position: absolute;
        height: trendsHeight(208);
        background: #181818;
        width: 100%;
        bottom: 0;
        display: flex;
        flex-direction: column;
        color: #FFFFFF;
        .top {
          height: trendsHeight(159);
          box-shadow: inset 0px -1px 0px 0px #474747;
          display: flex;
          justify-content: space-between;
          .left {
            margin-left: trendsWidth(120);
            .text1 {
              font-size: trendsWidth(18);
              font-weight: 500;
              margin-bottom: trendsHeight(24);
              margin-top: trendsHeight(36);
            }
            .text2 {
              font-size: trendsWidth(14);
              font-weight: 400;
            }
          }
          .right {
            display: flex;
            align-items: center;
            margin-right: trendsWidth(120);
            .img-box {
              width: trendsWidth(80);
              height: trendsHeight(80);
              margin-right: trendsWidth(16);
              .img {
                position: static;
                width: 100%;
                height: 100%;
              }
            }
            .text-box {
              font-size: trendsWidth(12);
            }
          }
        }
        .bottom {
          flex: 1;
          display: flex;
          box-sizing: border-box;
          align-items: center;
          justify-content: space-between;
          font-size: trendsWidth(12);
          color: #999;
          justify-content: space-evenly;
          flex-wrap: wrap;
          a {
            color: #999;
            text-decoration: none;
          }
        }
      }
    }
  }
}

.home-page {
  overflow-y: auto;
  overflow-x: hidden;
  .title {
    width: trendsWidth(1440);
    height: trendsHeight(72);
    background: rgba(255,255,255,0);
    box-shadow: inset 0px -1px 0px 0px rgba(255,255,255,0.2);
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
    z-index: 1000;
    .left {
      margin-left: trendsWidth(120);
      display: flex;
      align-items: center;
      .img {
        width: trendsWidth(40);
        height: trendsHeight(41);
        margin-right: trendsWidth(12);
      }
      .img-text {
        width: trendsWidth(98);
        height: trendsHeight(19);
      }
    }
    .tabs-box {
      margin-right: trendsWidth(119);
      display: flex;
      .tab-item {
        margin-left: trendsHeight(40);
        font-size: trendsWidth(16);
        font-weight: 600;
        color: #FFFFFF;
        line-height: trendsHeight(22);
        padding-top: trendsHeight(25);
        cursor: pointer;
      }
      .active {
        border-bottom: trendsHeight(3) solid #4BB773;
      }
    }
  }
  .green-title {
    background: #FFFFFF;
    box-shadow: inset 0px -1px 0px 0px rgba(255,255,255,0.2);
    .tabs-box {
      .tab-item {
        color: #181818;
      }
      .active {
        color: #4BB773;
      }
    }
  }
  .box {
    height: trendsHeight(900);
    position: relative;
    .img {
      position: absolute;
      width: 100%;
      height: 100%;
    }
    .xiala-img {
      position: absolute;
      bottom: trendsHeight(46);
      width: trendsWidth(20.17);
      height: trendsHeight(11.78);
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
    }
  }
  .box1, .box2 {
    .quan-box {
      position: absolute;
      width: trendsWidth(474);
      height: trendsWidth(474);
      background-color: #000000;
      opacity: 0.2;
      border-radius: 50%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .box1 {
    .text-box {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: trendsWidth(324);
      height: trendsHeight(144);
      color: #fff;
      text-align: center;
      z-index: 10;
      .text1 {
        font-size: trendsWidth(52);
      }
      .text2 {
        font-size: trendsWidth(36);
      }
    }
    .green-1 {
      width: trendsWidth(101);
      height: trendsHeight(101);
      background-color: #4BB773;
      opacity: 0.5;
      border-radius: 50%;
      top: trendsHeight(235);
      left: trendsWidth(492);
      position: absolute;
    }
    .green-2 {
      width: trendsWidth(62);
      height: trendsHeight(62);
      background: #4BB773;
      opacity: 0.5;
      border-radius: 50%;
      bottom: trendsHeight(371);
      right: trendsWidth(539);
      position: absolute;
    }
  }
  .box2 {
    .text-box {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      text-align: center;
      z-index: 10;
      width: trendsWidth(660);
      .text1 {
        font-size: trendsWidth(52);
      }
      .text2 {
        margin-top: trendsHeight(28);
        font-size: trendsWidth(18);
        font-weight: 400;
        color: #FFFFFF;
      }
    }
    .green-1 {
      width: trendsWidth(101);
      height: trendsHeight(101);
      background-color: #4BB773;
      opacity: 0.5;
      border-radius: 50%;
      top: trendsHeight(235);
      left: trendsWidth(492);
      position: absolute;
    }
    .green-2 {
      width: trendsWidth(62);
      height: trendsHeight(62);
      background: #4BB773;
      opacity: 0.5;
      border-radius: 50%;
      bottom: trendsHeight(262);
      left: trendsWidth(850);
      position: absolute;
    }
  }
  .box3, .box4 {
    .img1 {
      width: trendsWidth(60);
      height: trendsWidth(60);
      position: absolute;
      top: trendsHeight(240);
      left: trendsWidth(120);
    }
    .img2 {
      width: trendsWidth(411);
      height: trendsHeight(47);
      position: absolute;
      top: trendsHeight(340);
      left: trendsWidth(120);
    }
    .img3, .img4 {
      position: absolute;
      width: trendsWidth(978);
      height: trendsHeight(176);
      top: trendsHeight(240);
    }
    .img4 {
      top: trendsHeight(482);
      left: trendsWidth(450);
    }
    .img5 {
      position: absolute;
      width: trendsWidth(478);
      height: trendsHeight(731);
      top: trendsHeight(116);
      right: trendsWidth(159);
    }
    .text {
      width: trendsWidth(600);
      height: trendsHeight(84);
      font-size: trendsWidth(16);
      font-weight: 400;
      color: #666666;
      position: absolute;
      left: trendsWidth(120);
      top: trendsHeight(449);
    }
  }
  .box4 {
    .img1 {
      left: trendsWidth(1248);
      top: trendsHeight(242);
    }
    .img2 {
      width: trendsWidth(446);
      height: trendsHeight(60);
      top: trendsHeight(358);
      left: trendsWidth(720);
    }
    .img5 {
      top: trendsHeight(116);
      left: trendsWidth(144);
    }
    .text {
      width: trendsWidth(600);
      height: trendsHeight(112);
      font-size: trendsWidth(16);
      font-weight: 400;
      color: #666666;
      top: trendsHeight(449);
      left: trendsWidth(720);
    }
  }
  .box5 {
    .scroll-box {
      height: 100%;
      overflow-x: auto;
    }
    ::-webkit-scrollbar{
      display:none;
    }
    
    .center {
      display: flex;
      flex-wrap: nowrap;
      .box {
        width: trendsWidth(1440);
        .green-yuan, .img-1 {
          position: absolute;
          width: trendsWidth(520);
          height: trendsHeight(520);
          background: #4BB773;
          border-radius: 50%;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        .img-1 {
          width: trendsWidth(709);
          height: trendsHeight(632);
          background: none;
          border-radius: 0;
        }
        .img-2 {
          width: trendsWidth(605);
          height: trendsHeight(63);
          position: absolute;
          bottom: trendsHeight(96);
          left: trendsWidth(123);
        }
        .img-3 {
          position: absolute;
          width: trendsWidth(32);
          height: trendsHeight(32);
          bottom: trendsHeight(147);
          right: trendsWidth(498);
        }
        .img-4 {
          position: absolute;
          width: trendsWidth(66);
          height: trendsHeight(32);
          bottom: trendsHeight(144);
          right: trendsWidth(409);
        }
        .text {
          position: absolute;
          width: trendsWidth(358);
          font-size: trendsWidth(16);
          font-weight: 400;
          color: #666666;
          bottom: trendsHeight(98);
          right: trendsWidth(120);
        }
      }
    }
    .img-left, .img-right {
      position: absolute;
      width: trendsWidth(60);
      height: trendsHeight(60);
      top: 50%;
      transform: translateY(-50%);
      left: trendsWidth(186);
      cursor: pointer;
    }
    .img-right {
      left: auto;
      right: trendsWidth(186);
    }
  }
  .box6 {
    position: relative;
    .img-1 {
      position: absolute;
      height: trendsHeight(692);
      width: 100%;
    }
    .img-2 {
      position: absolute;
      width: trendsWidth(356);
      height: trendsHeight(32);
      top: trendsHeight(273);
      left: trendsWidth(120);
    }
    .green-line {
      position: absolute;
      width: trendsWidth(40);
      height: trendsHeight(6);
      background: #4BB773;
      top: trendsHeight(299);
      left: trendsWidth(498);
    }
    .tootip-text {
      width: trendsWidth(600);
      height: trendsHeight(72);
      font-size: trendsWidth(14);
      font-weight: 400;
      color: #666666;
      position: absolute;
      top: trendsHeight(341);
      left: trendsWidth(120);
    }
    .footer {
      position: absolute;
      height: trendsHeight(208);
      background: #181818;
      width: 100%;
      bottom: 0;
      display: flex;
      flex-direction: column;
      color: #FFFFFF;
      .top {
        height: trendsHeight(159);
        box-shadow: inset 0px -1px 0px 0px #474747;
        display: flex;
        justify-content: space-between;
        .left {
          margin-left: trendsWidth(120);
          .text1 {
            font-size: trendsWidth(18);
            font-weight: 500;
            margin-bottom: trendsHeight(24);
            margin-top: trendsHeight(36);
          }
          .text2 {
            font-size: trendsWidth(14);
            font-weight: 400;
          }
        }
        .right {
          display: flex;
          align-items: center;
          margin-right: trendsWidth(120);
          .img-box {
            width: trendsWidth(80);
            height: trendsHeight(80);
            margin-right: trendsWidth(16);
            .img {
              position: static;
              width: 100%;
              height: 100%;
            }
          }
          .text-box {
            font-size: trendsWidth(12);
          }
        }
      }
      .bottom {
        flex: 1;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        justify-content: space-between;
        font-size: trendsWidth(12);
        color: #999;
        justify-content: space-evenly;
        flex-wrap: wrap;
      }
    }
  }
}